import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

    header = (
      <div className="header-wrap">
        <h2 className="main-heading">
          <Link to="/">nanasan blog</Link>
        </h2>
        <span className="menu">
          <Link to="/about">About</Link>
        </span>
      </div>
    )

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, nanasan
      </footer>
    </div>
  )
}

export default Layout